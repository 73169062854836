// PrivateRoute.js
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AdminContext } from "context/AdminContext";

const PrivateRoute = ({ children, allowedRoles, ...rest }) => {
  const { state } = useContext(AdminContext);
  const { adminInfo } = state;

  console.log("adminInfo", adminInfo);
  console.log("allowedRoles", allowedRoles);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        adminInfo?.email && allowedRoles.includes(adminInfo.role) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
